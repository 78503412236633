@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.pink {
  color: #F54B94;
}

.grey {
  color: #A9A9A9;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .title {
    font-size: 3rem;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 2.2rem;
    line-height: 4vh;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .services .title {
    padding: 7vh;
  }
  .services .sub-title {
    font-size: 1.7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .services .action-btn {
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    width: 35%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .services .text-center-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .services .text-center-container p {
    width: 70%;
  }
  .services .black-line {
    width: 100%;
    background-color: #A9A9A9;
    height: 1px;
    margin-bottom: 1.5vh;
    margin-top: 1.5vh;
  }
}
@media (max-width: 768px) {
  .services .title {
    padding-top: 3vh;
    font-size: 1.8rem;
  }
  .services .sub-title {
    font-size: 1.3rem;
  }
  .services .normal-text {
    font-size: 1rem;
    color: #000000;
  }
  .services .action-btn {
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    width: 35%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.15rem;
  }
  .services .text-center-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .services .text-center-container p {
    width: 70%;
  }
  .services .black-line {
    width: 100%;
    background-color: #A9A9A9;
    height: 1px;
    margin-bottom: 2.5vh;
    margin-top: 2.5vh;
  }
}/*# sourceMappingURL=services.css.map */