@use '../../variables' as v;


@media (min-width:v.$xl) {
    .services{
        .title{
            padding:7vh;
        }
        .sub-title{
            font-size:1.7rem;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
    
        }
        .action-btn{
            background-color:v.$purple;
            color:white;
            border:none;
            border-radius:10px;
            width:35%;
            min-width:175px;
            padding:10px;
            font-size:1.3rem;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
        }
        .text-center-container{
            position: relative;
            height:100%;
            width:100%;
            p{
                width:70%;
            }
        }
    
        .black-line{
            width:100%;
            background-color:v.$grey;
            height:1px;
            margin-bottom:1.5vh;
            margin-top:1.5vh;
        }
        
    }
}

@media (max-width:v.$medium) {
    .services{
        .title{
            padding-top:3vh;
            font-size:1.8rem;
        }
        .sub-title{
            font-size:1.3rem;
        }

        .normal-text{
            font-size:1rem;
            color:v.$black;
        }
        .action-btn{
            background-color:v.$purple;
            color:white;
            border:none;
            border-radius:10px;
            width:35%;
            min-width:175px;
            padding:10px;
            font-size:1.15rem;
            
        }
        .text-center-container{
            position: relative;
            height:100%;
            width:100%;
            p{
                width:70%;
            }
        }
    
        .black-line{
            width:100%;
            background-color:v.$grey;
            height:1px;
            margin-bottom:2.5vh;
            margin-top:2.5vh;
        }
        
    }
}