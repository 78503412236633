$bp-xs:0px;
$bp-small:576px;
$bp-md:768px;
$bp-large:992px;
$bp-xl: 1200px;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.lato-thin {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .lato-light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .lato-black {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .lato-thin-italic {
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .lato-light-italic {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .lato-regular-italic {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .lato-bold-italic {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .lato-black-italic {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
 
//colors
$purple: #F54B94;
$black: #000000;
$grey:#A9A9A9;
$white: #F5F5FA;
$green: #07a59d;

.pink{
    color:$purple;
}

.grey{
    color:$grey;
}


//colors


//consistent font sizes and weights
h1{
    font-size:2.5rem;
    font-weight:700;
}
h2{
    font-size:1.8rem;
    font-weight:600;
}
h3{
    font-size:1.6rem;
    font-weight:500;
}
h4{
    font-size:1.4rem;
    font-weight:400;
}


$xl: 1200px;
$large: 992px;
$medium: 768px;
$small: 576px;


@media (min-width: $xl){
    .title{
      font-size:3rem;
    }
  
    .medium{
      font-size:1.1rem;
    }
}
@media (max-width: $medium){
    .title{
      font-size:2.2rem;
      line-height: 4vh;
    }
  
    .medium{
      font-size:1.1rem;
    }
}