@use '../../variables' as v;

@media only screen and (min-width:v.$bp-large){
    .nav-link{
        font-size:1.5rem;
    }
    .nav-btn{
        font-size:1.3rem;
        background-color:v.$purple;
        color:white;
        border:none;
        border-radius:10px;
        padding-left:20px;
        padding-right:20px;
    }
    .nav-main{
        width:50px;

        img{
            width:60px;
        }
    }
}

@media only screen and (max-width:v.$bp-large){
    .nav-link{
        font-size:1.3rem;
    }
    .nav-btn{
        font-size:1.3rem;
        background-color:v.$purple;
        color:white;
        border:none;
        border-radius:10px;
        padding-left:20px;
        padding-right:20px;
    }
    .nav-main{
        width:50px;
        
        img{
            width:50px;
        }
    }

    
}