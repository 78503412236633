@use '../../variables' as v;


@media (min-width: v.$xl){
    .homepage{
        background-image:
    linear-gradient(0deg, rgba(0,0,0,0.5), rgba(255,255,255,0.9)),url('../images/background.jpg');
        height:100%;
        padding:15vh;

        .text{
            margin-top:7vh;
            text-align: center;
            width:60%;
            margin-left:20%;
        }

        .action-btn{
            background-color:v.$purple;
            color:white;
            border:none;
            border-radius:10px;
            width:35%;
            min-width:175px;
            padding:10px;
            font-size:1.3rem;
        }

        .action-btn-white{
            background-color:v.$white;
            color:v.$black;
            border:none;
            border-radius:10px;
            width:35%;
            min-width:175px;
            padding:10px;
            font-size:1.3rem;
        }
    }
}

@media (max-width: v.$medium){
    .homepage{
        background-image:
    linear-gradient(0deg, rgba(0,0,0,0.5), rgba(255,255,255,0.9)),url('../images/background.jpg');
        height:100%;
        padding-bottom:7vh;
        padding-top:11vh;
        background-position: right;
        
        .action-btn{
            background-color:v.$purple;
            color:white;
            border:none;
            border-radius:10px;
            width:45%; 
            min-width:175px;
            padding:10px;
            font-size:1.2rem;
        }

        .action-btn-white{
            background-color:v.$white;
            color:v.$black;
            border:none;
            border-radius:10px;
            width:45%;
            min-width:175px;
            padding:10px;
            font-size:1.2rem;
            margin-left:5%;
        }
    }
}