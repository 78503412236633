@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.pink {
  color: #F54B94;
}

.grey {
  color: #A9A9A9;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .title {
    font-size: 3rem;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 2.2rem;
    line-height: 4vh;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media (min-width: 1200px) {
  .homepage {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.9)), url("../images/background.jpg");
    height: 100%;
    padding: 15vh;
  }
  .homepage .text {
    margin-top: 7vh;
    text-align: center;
    width: 60%;
    margin-left: 20%;
  }
  .homepage .action-btn {
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    width: 35%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.3rem;
  }
  .homepage .action-btn-white {
    background-color: #F5F5FA;
    color: #000000;
    border: none;
    border-radius: 10px;
    width: 35%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.3rem;
  }
}
@media (max-width: 768px) {
  .homepage {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.9)), url("../images/background.jpg");
    height: 100%;
    padding-bottom: 7vh;
    padding-top: 11vh;
    background-position: right;
  }
  .homepage .action-btn {
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    width: 45%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.2rem;
  }
  .homepage .action-btn-white {
    background-color: #F5F5FA;
    color: #000000;
    border: none;
    border-radius: 10px;
    width: 45%;
    min-width: 175px;
    padding: 10px;
    font-size: 1.2rem;
    margin-left: 5%;
  }
}/*# sourceMappingURL=homepage.css.map */