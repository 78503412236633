@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.pink {
  color: #F54B94;
}

.grey {
  color: #A9A9A9;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .title {
    font-size: 3rem;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 2.2rem;
    line-height: 4vh;
  }
  .medium {
    font-size: 1.1rem;
  }
}
@media only screen and (min-width: 992px) {
  .nav-link {
    font-size: 1.5rem;
  }
  .nav-btn {
    font-size: 1.3rem;
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .nav-main {
    width: 50px;
  }
  .nav-main img {
    width: 60px;
  }
}
@media only screen and (max-width: 992px) {
  .nav-link {
    font-size: 1.3rem;
  }
  .nav-btn {
    font-size: 1.3rem;
    background-color: #F54B94;
    color: white;
    border: none;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .nav-main {
    width: 50px;
  }
  .nav-main img {
    width: 50px;
  }
}/*# sourceMappingURL=navbar.css.map */